html {
}
body {
  background-color: #fff;
  font-weight: 400;
}
.u-fontRegular {
  font-weight: 400;
}
.u-fontMedium {
  font-weight: 500;
}

.BottomBar {
  left: 280px;
}

.BubbleChat p {
  font-size: inherit;
  line-height: inherit;
}

.BubbleChat-time {
  color: #6b778c !important; /* same as u-textGray */
}

.EmptyState-heading,
.EmptyState-description {
  color: #6b778c !important; /* same as u-textGray */
}

.u-borderWhite {
  border-color: white;
}

/* Flip content: */
.FlipCard {
  perspective: 1000;
  position: relative;
  margin: 10px auto;
}
.FlipCardContent {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.3s linear;
}
.FlipCardItem {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  visibility: visible;
}
.FlipCardItem--back {
  display: block;
  transform: rotateY(180deg);
  visibility: hidden;
}

.FlipCard.isBack .FlipCardContent {
  transform: rotateY(180deg);
}
.FlipCard.isBack .FlipCardItem {
  visibility: hidden;
}
.FlipCard.isBack .FlipCardItem--back {
  visibility: visible;
}

/*
 * https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.Slider-track {
  background-color: transparent;
}

::placeholder {
  text-transform: initial;
}

.FormInput-select:disabled {
  background-color: #ebecf0;
}

#beta-testing a {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  #beta-testing .title {
    padding: 0 !important;
  }

  #beta-testing .video-section h1 {
    padding: 0 !important;
  }

  #beta-testing .banner {
    height: 85px !important;
  }
}

.Tab-item:nth-child(1).is-active {
  border-left: 1px solid #dfe1e6;
}
.Tab-item:nth-child(2).is-active {
  border-right: 1px solid #dfe1e6;
}

.youtube-iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.youtube-iframe-wrap {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
